import React from "react"

type DividerProp = {
  margin?: string
}

const Divider = ({ margin = "25px" }: DividerProp) => {
  return (
    <div
      style={{
        borderTop: "2px solid black",
        height: "40px",
        width: "100%",
        marginBottom: margin,
      }}
    ></div>
  )
}

export default Divider
